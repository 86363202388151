import {WrappedResponse} from '../types/http-request';
import {Organization} from '../types/organizations';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

export interface IOrganizationResponse {
  timestamp: string;
  status: string;
  data: Organization[];
}

export async function getOrganizations() {
  return instance()
    .get<IOrganizationResponse>('directory/organizations')
    .then(response => sortAlpha(response.data.data, 'name'));
}

export async function getOrganizationById(idOrganization: string) {
  return instance()
    .get<WrappedResponse<Organization>>(
      `directory/organizations/${idOrganization}`,
    )
    .then(response => response.data.data);
}

export function createOrganization(data) {
  return instance().post('directory/organizations', data);
}

export function updateOrganization(data) {
  return instance().put(`directory/organizations/${data.idOrganization}`, data);
}
