import {createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface ViewMore {
  day?: string;
}

export interface ViewMoreState {
  data: ViewMore;
  error: string | null;
}

const initialState = {data: [], error: null} as ViewMoreState;
const viewMoreSlice = createSlice({
  name: 'viewMore',
  initialState,
  reducers: {
    setViewMore(state, action) {
      const x = '';
    },
  },
});

export const getViewMoreSelector = (state: RootState) => state.viewMore.data;
export const isViewMoreLoading = state => state.viewMore.isLoading;
export const getErrorRefreshSelector = state => state.viewMore.error;

export default viewMoreSlice.reducer;
export const {setViewMore} = viewMoreSlice.actions;
