import React, {Suspense, lazy, useEffect, useMemo, useState} from 'react';

import {Route, Routes} from 'react-router-dom';

import {InitLayout} from '../components/Layout/InitLayout';
import {SidebarLayout} from '../components/Layout/SidebarLayout';
import {LoaderSpinner} from '../components/LoaderSpinner';
import {NotFound} from '../components/NotFound';
import {getUserRoles} from '../components/ProtectedComponent';
import {Restricted} from '../components/Restricted';
import {Home} from '../pages/home';

const Organizations = lazy(() => import('../pages/organizations'));
const Roles = lazy(() => import('../pages/roles'));
const Plans = lazy(() => import('../pages/plans'));
const Users = lazy(() => import('../pages/users'));
const Locations = lazy(() => import('../pages/locations'));
const LocationsTypes = lazy(() => import('../pages/locationsTypes'));
const Projects = lazy(() => import('../pages/projects'));
const Bills = lazy(() => import('../pages/bills'));
const NewForm = lazy(() => import('../pages/forms/form/NewForm'));
const Forms = lazy(() => import('../pages/forms'));
const Documents = lazy(() => import('../pages/documents'));
const EventType = lazy(() => import('../pages/events'));
const Analytics = lazy(() => import('../pages/analytics'));
const Report = lazy(() => import('../pages/analytics/Report'));
const ObjectivesTypes = lazy(() => import('../pages/objectivesTypes'));
const Tours = lazy(() => import('../pages/tours'));
const Schedule = lazy(() => import('../pages/schedule'));
const Incidents = lazy(() => import('../pages/incidents'));
const CustomFields = lazy(() => import('../pages/customFields'));
const Kpis = lazy(() => import('../pages/kpis'));
const AssignKpis = lazy(() => import('../pages/assignKpis'));
const Periods = lazy(() => import('../pages/periods'));
const Policies = lazy(() => import('../pages/policies'));
const FormResponses = lazy(() => import('../pages/formResponses'));
const BillsTypes = lazy(() => import('../pages/billsTypes'));
const SubPolicies = lazy(() => import('../pages/policies/SubPolicies'));
const ContentReportChecks = lazy(
  () => import('../pages/reports/ContentReportChecks'),
);
const ContentReportMileage = lazy(
  () => import('../pages/reports/ContentReportMileage'),
);

interface IRouteMapping {
  allowedRoles: string[];
  path: string;
  element: React.JSX.Element;
}

const routeMapping: IRouteMapping[] = [
  {
    path: '/home',
    allowedRoles: ['super-admin', 'supervisor', 'project-admin', 'org-admin'],
    element: <Home />,
  },
  {
    path: '/organization',
    allowedRoles: ['super-admin'],
    element: <Organizations />,
  },
  {
    path: '/users',
    allowedRoles: [
      'super-admin',
      'project-admin',
      'happify-directory-service.users.admin',
    ],
    element: <Users />,
  },
  {
    path: '/users/:id',
    allowedRoles: [
      'super-admin',
      'project-admin',
      'happify-directory-service.users.admin',
    ],
    element: <Users />,
  },
  {
    path: '/roles',
    allowedRoles: ['super-admin'],
    element: <Roles />,
  },
  {
    path: '/locations',
    allowedRoles: ['super-admin', 'happify-location-service.location.admin'],
    element: <Locations />,
  },
  {
    path: '/locationsTypes',
    allowedRoles: ['super-admin'],
    element: <LocationsTypes />,
  },
  {
    path: '/event-type',
    allowedRoles: ['super-admin'],
    element: <EventType />,
  },
  {
    path: '/plans',
    allowedRoles: ['super-admin'],
    element: <Plans />,
  },
  {
    path: '/projects',
    allowedRoles: ['super-admin'],
    element: <Projects />,
  },
  {
    path: '/forms/edit/',
    allowedRoles: ['super-admin', 'happify-forms-service.forms.create'],
    element: <NewForm />,
  },
  {
    path: '/forms/edit/:id',
    allowedRoles: ['super-admin', 'happify-forms-service.forms.edit'],
    element: <NewForm />,
  },
  {
    path: '/forms',
    allowedRoles: ['super-admin', 'happify-forms-service.forms.list'],
    element: <Forms />,
  },
  {
    path: '/objectives-types',
    allowedRoles: ['super-admin'],
    element: <ObjectivesTypes />,
  },
  {
    path: '/incidents',
    allowedRoles: ['super-admin'],
    element: <Incidents />,
  },
  {
    path: '/bills',
    allowedRoles: ['super-admin', 'org-admin'],
    element: <Bills />,
  },
  {
    path: '/kpis',
    allowedRoles: [
      'super-admin',
      'happify-kpi-service.kpi.template-load',
      'happify-kpi-service.kpi.admin',
    ],
    element: <Kpis />,
  },
  {
    path: '/assign-kpis',
    allowedRoles: [
      'super-admin',
      'happify-kpi-service.kpi.template-load',
      'happify-kpi-service.kpi.admin',
    ],
    element: <AssignKpis />,
  },
  {
    path: '/custom-fields',
    allowedRoles: ['super-admin'],
    element: <CustomFields />,
  },
  {
    path: '/periods',
    allowedRoles: ['super-admin', 'happify-kpi-service.kpi-period.view'],
    element: <Periods />,
  },
  {
    path: '/schedule',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <Schedule />,
  },
  {
    path: '/tours',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <Tours />,
  },
  {
    path: '/analytics',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <Analytics />,
  },
  {
    path: '/analytics/report',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <Report />,
  },
  {
    path: '/analytics/report/:id',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <Report />,
  },
  {
    path: '/documents',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <Documents />,
  },
  {
    path: '/billsTypes',
    allowedRoles: ['super-admin'],
    element: <BillsTypes />,
  },
  {
    path: '/policies',
    allowedRoles: ['super-admin'],
    element: <Policies />,
  },
  {
    path: '/policies/sub-policies',
    allowedRoles: ['super-admin'],
    element: <SubPolicies />,
  },
  {
    path: '/policies/sub-policies/:idOrganization/:id',
    allowedRoles: ['super-admin'],
    element: <SubPolicies />,
  },
  {
    path: '/report-checks',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <ContentReportChecks />,
  },
  {
    path: '/report-mileage',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <ContentReportMileage />,
  },
  {
    path: '/answers',
    allowedRoles: ['super-admin', 'supervisor'],
    element: <FormResponses />,
  },
];

export function AppRouter() {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    setRoles(getUserRoles());
  }, []);

  const routes = useMemo(() => {
    return routeMapping
      .filter(route => {
        return route.allowedRoles.some(role => roles.includes(role));
      })
      .map(route => (
        <Route
          key={route.path}
          path={`/:idOrganization${route.path}`}
          element={
            <Suspense fallback={<LoaderSpinner />}>{route.element}</Suspense>
          }
        />
      ));
  }, [roles]);

  if (roles.length === 0) {
    return <LoaderSpinner />;
  }

  return (
    <Routes>
      <Route element={<SidebarLayout />}>{routes}</Route>
      <Route path='/' element={<InitLayout />} />
      <Route path='*' element={<Restricted />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}
