import React, {useEffect, useState} from 'react';

import {useDispatch} from 'react-redux';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

import {IoSettingsSharp} from 'react-icons/io5';
import {useParams} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';

import {
  AdministrationIcon,
  AnalitysIcon,
  AnswersIcon,
  AssignsKpisIcon,
  CalendarizacionIcon,
  ChecksIcon,
  CronogramaIcon,
  CustomFieldsIcon,
  DocumentosIcon,
  FacturasIcon,
  FormsIcon,
  FormulariosIcon,
  GeoUbicacionIcon,
  KilometrajeIcon,
  KpisIcon,
  MileageIcon,
  ModuleKpisIcon,
  OrganizacionesIcon,
  PeriodIcon,
  PlanesIcon,
  PolicyIcon,
  ProyectosIcon,
  ReportsIcon,
  RolesIcon,
  SupportCenterIcon,
  TipoFacturasIcon,
  TipoObjetivosIcon,
  TipoUbicacionIcon,
  TiposEventosIcon,
  TiposIncidenciasIcon,
  UbicacionesIcon,
  UsuariosIcon,
  ViaticosIcon,
} from '../assets/icons';
import {SideBar} from '../components';
import {ProtectedComponent} from '../components/ProtectedComponent';
import Logo from '../components/lib/Logo';
import {addAssignKpiFilter} from '../slices/assignKpiFilterSlice';
import {addBillFilter} from '../slices/billFilterSlice';
import {addCalendarFilter} from '../slices/calendarFilterSlice';
import {addTourFilter} from '../slices/tourFilterSlice';
import {addTours} from '../slices/toursSlice';
import * as LocalStorageUtils from '../utils/LocalStorageUtils';
import {hasAccess} from '../utils/user-access';
import CONFIG from './../config';

function NameButton({children, isSidebarOpen}) {
  if (!isSidebarOpen) {
    return null;
  }
  return <span>{children}</span>;
}

function AccordionBars({isSidebarOpen}) {
  if (!isSidebarOpen) {
    return null;
  }
  return <AccordionIcon />;
}

function SidebarMenu() {
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    LocalStorageUtils.getBoolean('isSidebarOpen'),
  );
  const [typeUser, setTypeUser] = useState<
    'supervisor' | 'all' | 'tigo-project-admin' | null
  >(null);

  const {idOrganization} = useParams();

  useEffect(() => {
    if (hasAccess('tigo-project-admin')) {
      setTypeUser('tigo-project-admin');
    } else if (hasAccess('supervisor')) {
      setTypeUser('supervisor');
    } else {
      setTypeUser('all');
    }
    const interval = setInterval(() => {
      if (hasAccess('tigo-project-admin')) {
        setTypeUser('tigo-project-admin');
      } else if (hasAccess('supervisor')) {
        setTypeUser('supervisor');
      } else {
        setTypeUser('all');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const toggleIsOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
    LocalStorageUtils.setBoolean('isSidebarOpen', !isSidebarOpen);
  };

  const clearInputs = () => {
    dispatch(addCalendarFilter([]));
    dispatch(addTourFilter([]));
    dispatch(addTours([]));
    dispatch(addAssignKpiFilter([]));
    dispatch(addBillFilter([]));
  };

  const validateStyles = isSidebarOpen
    ? {justify: 'space-between', margin: 2, size: 'lg'}
    : {justify: 'center', margin: 0, size: 'sm'};

  if (!typeUser) {
    return null;
  }

  return (
    <SideBar isCollapsed={isSidebarOpen}>
      <SideBar.Header>
        <Flex direction='column'>
          <Logo size={validateStyles.size} onClick={toggleIsOpen} />
          <Text fontSize='xs' textAlign='center'>
            {CONFIG.VERSION}
          </Text>
        </Flex>
      </SideBar.Header>
      <SideBar.Menu>
        <Accordion allowMultiple w='100%'>
          <ProtectedComponent
            allowedRoles={[
              'super-admin',
              'project-admin',
              'happify-directory-service.users.admin',
            ]}>
            <AccordionItem border='none'>
              <AccordionButton
                py='14px'
                _focus={{outline: 'none'}}
                _hover={{bg: 'darkBlue.400'}}
                h='max'
                justifyContent={validateStyles.justify}>
                <HStack fontSize={14}>
                  <AdministrationIcon
                    w='25px'
                    h='25px'
                    mr={validateStyles.margin}
                  />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Administración
                  </NameButton>
                </HStack>
                <AccordionBars isSidebarOpen={isSidebarOpen} />
              </AccordionButton>
              <AccordionPanel p={0} bg='darkBlue.600'>
                <VStack>
                  <ProtectedComponent allowedRoles={['super-admin']}>
                    <Link
                      textAlign='left'
                      as={RouterLink}
                      to={`/${idOrganization}/organization`}
                      _focus={{outline: 'none'}}>
                      <OrganizacionesIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Organización
                      </NameButton>
                    </Link>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/projects`}
                      _focus={{outline: 'none'}}>
                      <ProyectosIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Proyectos
                      </NameButton>
                    </Link>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/plans`}
                      _focus={{outline: 'none'}}>
                      <PlanesIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Planes
                      </NameButton>
                    </Link>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/roles`}
                      _focus={{outline: 'none'}}>
                      <RolesIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Roles
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/users`}
                    _focus={{outline: 'none'}}>
                    <UsuariosIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Usuarios
                    </NameButton>
                  </Link>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </ProtectedComponent>
          <ProtectedComponent allowedRoles={['super-admin', 'supervisor']}>
            <AccordionItem border='none'>
              <AccordionButton
                py='16px'
                _focus={{outline: 'none'}}
                _hover={{bg: 'darkBlue.400'}}
                h='max'
                justifyContent={validateStyles.justify}>
                <HStack fontSize={14}>
                  <CalendarizacionIcon
                    w='25px'
                    h='25px'
                    mr={validateStyles.margin}
                  />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Calendarización
                  </NameButton>
                </HStack>
                <AccordionBars isSidebarOpen={isSidebarOpen} />
              </AccordionButton>
              <AccordionPanel p={0} bg='darkBlue.600'>
                <VStack>
                  <ProtectedComponent allowedRoles={['super-admin']}>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/event-type`}
                      _focus={{outline: 'none'}}>
                      <TiposEventosIcon w='25px' h='25px' />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Tipos <br /> de evento
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/schedule`}
                    onClick={clearInputs}
                    _focus={{outline: 'none'}}>
                    <CronogramaIcon w='25px' h='25px' />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Cronograma
                    </NameButton>
                  </Link>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </ProtectedComponent>
          <AccordionItem border='none'>
            <AccordionButton
              py='16px'
              _focus={{outline: 'none'}}
              _hover={{bg: 'darkBlue.400'}}
              h='max'
              justifyContent={validateStyles.justify}>
              <HStack fontSize={14}>
                <GeoUbicacionIcon
                  w='25px'
                  h='25px'
                  mr={validateStyles.margin}
                />
                <NameButton isSidebarOpen={isSidebarOpen}>
                  Geo ubicación
                </NameButton>
              </HStack>
              <AccordionBars isSidebarOpen={isSidebarOpen} />
            </AccordionButton>
            <AccordionPanel p={0} bg='darkBlue.600'>
              <VStack>
                <ProtectedComponent allowedRoles={['super-admin']}>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/locationsTypes`}
                    _focus={{outline: 'none'}}>
                    <TipoUbicacionIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Tipos de <br /> Ubicaciones
                    </NameButton>
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent
                  allowedRoles={[
                    'super-admin',
                    'happify-location-service.location.admin',
                  ]}>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/locations`}
                    _focus={{outline: 'none'}}>
                    <UbicacionesIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Ubicaciones
                    </NameButton>
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent
                  allowedRoles={['super-admin', 'supervisor']}>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/tours`}
                    onClick={clearInputs}
                    _focus={{outline: 'none'}}>
                    <KilometrajeIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Recorridos
                    </NameButton>
                  </Link>
                </ProtectedComponent>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <ProtectedComponent allowedRoles={['super-admin', 'org-admin']}>
          <Accordion allowMultiple w='100%'>
            <AccordionItem border='none'>
              <AccordionButton
                py='16px'
                _focus={{outline: 'none'}}
                _hover={{bg: 'darkBlue.400'}}
                h='max'
                justifyContent={validateStyles.justify}>
                <HStack fontSize={14} textAlign='left'>
                  <ViaticosIcon w={7} h={7} mr={validateStyles.margin} />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Modulo de <br /> Viáticos
                  </NameButton>
                </HStack>
                <AccordionBars isSidebarOpen={isSidebarOpen} />
              </AccordionButton>
              <AccordionPanel p={0} bg='darkBlue.600'>
                <VStack>
                  <ProtectedComponent allowedRoles={['super-admin']}>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/policies`}
                      _focus={{outline: 'none'}}>
                      <PolicyIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Políticas
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                  <ProtectedComponent allowedRoles={['super-admin']}>
                    <Link
                      textAlign='left'
                      as={RouterLink}
                      to={`/${idOrganization}/billsTypes`}
                      _focus={{outline: 'none'}}>
                      <TipoFacturasIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Tipo de facturas
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                  <ProtectedComponent
                    allowedRoles={['super-admin', 'org-admin']}>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/bills`}
                      onClick={clearInputs}
                      _focus={{outline: 'none'}}>
                      <FacturasIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Facturas
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={['super-admin']}>
          <Link
            as={RouterLink}
            to={`/${idOrganization}/incidents`}
            _focus={{outline: 'none'}}>
            <TiposIncidenciasIcon />
            <NameButton isSidebarOpen={isSidebarOpen}>
              Tipos de <br /> incidencias
            </NameButton>
          </Link>
        </ProtectedComponent>
        <Link
          href='https://happify.atlassian.net/wiki/spaces/CDA/overview'
          target='_blank'
          _focus={{outline: 'none'}}>
          <SupportCenterIcon />
          <NameButton isSidebarOpen={isSidebarOpen}>
            Centro de <br /> Soporte
          </NameButton>
        </Link>
        <ProtectedComponent
          allowedRoles={['super-admin', 'happify-forms-service.forms.list']}>
          <Accordion allowMultiple w='100%'>
            <AccordionItem border='none'>
              <AccordionButton
                py='16px'
                _focus={{outline: 'none'}}
                _hover={{bg: 'darkBlue.400'}}
                h='max'
                justifyContent={validateStyles.justify}>
                <HStack fontSize={14} textAlign='left'>
                  <FormsIcon w={7} h={7} mr={validateStyles.margin} />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Módulo de formularios
                  </NameButton>
                </HStack>
                <AccordionBars isSidebarOpen={isSidebarOpen} />
              </AccordionButton>
              <AccordionPanel p={0} bg='darkBlue.600'>
                <VStack>
                  <ProtectedComponent
                    allowedRoles={[
                      'super-admin',
                      'happify-forms-service.forms.list',
                    ]}>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/forms`}
                      _focus={{outline: 'none'}}>
                      <FormulariosIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Formularios
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                  <ProtectedComponent allowedRoles={['super-admin']}>
                    <Link
                      as={RouterLink}
                      to={`/${idOrganization}/answers`}
                      _focus={{outline: 'none'}}>
                      <AnswersIcon />
                      <NameButton isSidebarOpen={isSidebarOpen}>
                        Respuesta de <br /> Formularios
                      </NameButton>
                    </Link>
                  </ProtectedComponent>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={['super-admin']}>
          <Link
            as={RouterLink}
            to={`/${idOrganization}/objectives-types`}
            _focus={{outline: 'none'}}>
            <TipoObjetivosIcon />
            <NameButton isSidebarOpen={isSidebarOpen}>
              Tipo de Objetivos
            </NameButton>
          </Link>
        </ProtectedComponent>
        <Link
          as={RouterLink}
          to={`/${idOrganization}/documents`}
          _focus={{outline: 'none'}}>
          <DocumentosIcon />
          <NameButton isSidebarOpen={isSidebarOpen}>Documentos</NameButton>
        </Link>
        <Accordion allowMultiple w='100%'>
          <AccordionItem border='none'>
            <AccordionButton
              py='16px'
              _focus={{outline: 'none'}}
              _hover={{bg: 'darkBlue.400'}}
              h='max'
              justifyContent={validateStyles.justify}>
              <HStack fontSize={14} textAlign='left'>
                <ReportsIcon w={7} h={7} mr={validateStyles.margin} />
                <NameButton isSidebarOpen={isSidebarOpen}>Reportes</NameButton>
              </HStack>
              <AccordionBars isSidebarOpen={isSidebarOpen} />
            </AccordionButton>
            <AccordionPanel p={0} bg='darkBlue.600'>
              <VStack>
                <Link
                  as={RouterLink}
                  to={`/${idOrganization}/analytics`}
                  _focus={{outline: 'none'}}>
                  <AnalitysIcon />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Analytics
                  </NameButton>
                </Link>
                <Link
                  as={RouterLink}
                  to={`/${idOrganization}/report-checks`}
                  _focus={{outline: 'none'}}>
                  <ChecksIcon />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Entrada y salida
                  </NameButton>
                </Link>
                <Link
                  as={RouterLink}
                  to={`/${idOrganization}/report-mileage`}
                  _focus={{outline: 'none'}}>
                  <MileageIcon />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Kilometraje por día
                  </NameButton>
                </Link>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowMultiple w='100%'>
          <AccordionItem border='none'>
            <AccordionButton
              py='16px'
              _focus={{outline: 'none'}}
              _hover={{bg: 'darkBlue.400'}}
              h='max'
              justifyContent={validateStyles.justify}>
              <HStack fontSize={14} textAlign='left'>
                <ModuleKpisIcon w={7} h={7} mr={validateStyles.margin} />
                <NameButton isSidebarOpen={isSidebarOpen}>
                  Modulo de <br /> Kpis
                </NameButton>
              </HStack>
              <AccordionBars isSidebarOpen={isSidebarOpen} />
            </AccordionButton>
            <AccordionPanel p={0} bg='darkBlue.600'>
              <VStack>
                <ProtectedComponent
                  allowedRoles={[
                    'super-admin',
                    'happify-kpi-service.kpi.template-load',
                    'happify-kpi-service.kpi.admin',
                  ]}>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/kpis`}
                    _focus={{outline: 'none'}}>
                    <KpisIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>Kpis</NameButton>
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent
                  allowedRoles={[
                    'super-admin',
                    'happify-kpi-service.kpi-period.view',
                  ]}>
                  <Link
                    textAlign='left'
                    as={RouterLink}
                    to={`/${idOrganization}/periods`}
                    _focus={{outline: 'none'}}>
                    <PeriodIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Periodos
                    </NameButton>
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent allowedRoles={['super-admin']}>
                  <Link
                    as={RouterLink}
                    to={`/${idOrganization}/assign-kpis`}
                    onClick={clearInputs}
                    _focus={{outline: 'none'}}>
                    <AssignsKpisIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Asignación de <br /> kpis
                    </NameButton>
                  </Link>
                </ProtectedComponent>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
          <ProtectedComponent allowedRoles={['super-admin']}>
            <AccordionItem border='none'>
              <AccordionButton
                py='14px'
                _focus={{outline: 'none'}}
                _hover={{bg: 'darkBlue.400'}}
                h='max'
                justifyContent={validateStyles.justify}>
                <HStack fontSize={14}>
                  <IoSettingsSharp
                    width='25px'
                    height='25px'
                    style={{marginRight: validateStyles.margin}}
                  />
                  <NameButton isSidebarOpen={isSidebarOpen}>
                    Configuración
                  </NameButton>
                </HStack>
                <AccordionBars isSidebarOpen={isSidebarOpen} />
              </AccordionButton>
              <AccordionPanel p={0} bg='darkBlue.600'>
                <VStack>
                  <Link
                    textAlign='left'
                    as={RouterLink}
                    to={`/${idOrganization}/custom-fields`}
                    _focus={{outline: 'none'}}>
                    <CustomFieldsIcon />
                    <NameButton isSidebarOpen={isSidebarOpen}>
                      Campos <br /> Personalizados
                    </NameButton>
                  </Link>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </ProtectedComponent>
        </Accordion>
      </SideBar.Menu>
    </SideBar>
  );
}

export default SidebarMenu;
