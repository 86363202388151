import React from 'react';

import {Box, Center, Heading, VStack} from '@chakra-ui/react';

import {Navigate} from 'react-router-dom';

import {getAttribute, hasAccess} from '../../utils/user-access';
import {OrganizationPickerList} from '../OrganizationPickerList';
import {UserSession} from '../UserSession';
import {HeaderLayout} from './HeaderLayout';

export function InitLayout() {
  if (!hasAccess('super-admin')) {
    const organization = getAttribute<string[]>('organizations', [])[0];
    return <Navigate to={`/${organization}/home`} />;
  }

  return (
    <>
      <Box px='20px'>
        <HeaderLayout modeView='right' header={<UserSession />} />
      </Box>
      <Center>
        <VStack>
          <Heading size='lg' my={8}>
            Happify Control
          </Heading>
          <OrganizationPickerList />
        </VStack>
      </Center>
    </>
  );
}
