import {createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface TourShowFilters {
  type?: boolean;
}

export interface TourShowFiltersState {
  data: TourShowFilters[];
  error: string | null;
}

const initialState = {
  data: [{type: false}],
  error: null,
} as TourShowFiltersState;
const tourShowFiltersSlice = createSlice({
  name: 'tourShowFilters',
  initialState,
  reducers: {
    addTourShowFilters(state, action) {
      const newData = [] as any;
      newData.push(action.payload);
      state.data = newData;
    },
  },
});

export const getTourShowFiltersSelector = (state: RootState) =>
  state.tourShowFilters.data;
export const getErrorSelector = state => state.tourShowFilters.error;

export default tourShowFiltersSlice.reducer;
export const {addTourShowFilters} = tourShowFiltersSlice.actions;
