import {Button, Center, VStack} from '@chakra-ui/react';

import {Link as RouterLink} from 'react-router-dom';

import {PageRestricted} from '../assets/icons/PageRestricted';

export function Restricted() {
  return (
    <Center>
      <VStack>
        <PageRestricted w='100%' h='30vh' mt='30vh' />
        <br />
        <Button as={RouterLink} colorScheme='blue' to='/'>
          Volver al inicio
        </Button>
      </VStack>
    </Center>
  );
}
