import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CronogramaIcon = createIcon({
  displayName: 'CronogramaIcon',
  viewBox: '0 0 35 35',
  path: (
    <>
      <mask
        id='mask0'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='35'
        height='35'>
        <path
          d='M29.6311 5.52468C36.2044 12.098 36.2044 22.7567 29.6311 29.33C23.0578 35.9047 12.3991 35.9047 5.82578 29.33C-0.748891 22.7567 -0.748891 12.098 5.82578 5.52468C12.3991 -1.04998 23.0578 -1.04998 29.6311 5.52468Z'
          fill='#FFB800'
        />
      </mask>
      <g mask='url(#mask0)'>
        <path
          d='M29.6311 5.52468C36.2044 12.098 36.2044 22.7567 29.6311 29.33C23.0578 35.9047 12.3991 35.9047 5.82578 29.33C-0.748891 22.7567 -0.748891 12.098 5.82578 5.52468C12.3991 -1.04998 23.0578 -1.04998 29.6311 5.52468Z'
          fill='#FFB800'
        />
        <path
          d='M22.1401 18.4212H16.7573V16.5652H22.1401V18.4212Z'
          fill='#F2F2F5'
        />
        <path
          d='M18.6134 18.4212H16.7587V9.22785H18.6134V18.4212Z'
          fill='#F2F2F5'
        />
        <path
          d='M18.8947 5.36386H16.8093V-0.913442H18.8947V5.36386Z'
          fill='#F2F2F5'
        />
        <path
          d='M18.8947 36.1239H16.8093V29.4852H18.8947V36.1239Z'
          fill='#F2F2F5'
        />
        <path d='M-3 16.3799H5.79069V18.4652H-3V16.3799Z' fill='#F2F2F5' />
        <path
          d='M29.912 16.3799H35.4641V18.4652H29.912V16.3799Z'
          fill='#F2F2F5'
        />
      </g>
    </>
  ),
});
