import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ChecksIcon = createIcon({
  displayName: 'ChecksIcon',
  viewBox: '0 0 101 88',
  path: (
    <>
      <path
        d='M63 10.4615V4H56V10.4615V16.9231V23.3846H63V16.9231V10.4615Z'
        fill='#73B3AB'
      />
      <path
        d='M28 10.4615V4H21V10.4615V16.9231V23.3846H28V16.9231V10.4615Z'
        fill='#73B3AB'
      />
      <path d='M7 36.3076H24.5V55.6922H7V36.3076Z' fill='white' />
      <path d='M52.5 55.6922H31.5V36.3076H52.5V55.6922Z' fill='white' />
      <path d='M52.5 81.5383H31.5V62.1537H52.5V81.5383Z' fill='white' />
      <path d='M59.5 81.5383V62.1537H77L77.0035 81.5383H59.5Z' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77 10.4614H63V16.923H77V29.846H7V16.923H21V10.4614H7C5.14405 10.4631 3.36465 11.1445 2.0523 12.3559C0.739947 13.5673 0.00185317 15.2098 0 16.923V81.5383C0.00185317 83.2515 0.739947 84.8941 2.0523 86.1055C3.36465 87.3169 5.14405 87.9982 7 87.9999H77C78.8559 87.9982 80.6354 87.3169 81.9477 86.1055C83.2601 84.8941 83.9981 83.2515 84 81.5383V16.923C83.9981 15.2098 83.2601 13.5673 81.9477 12.3559C80.6354 11.1445 78.8559 10.4631 77 10.4614ZM56 10.4614H28V16.923H56V10.4614ZM7 36.3076H24.5V55.6922H7V36.3076ZM52.5 81.5383H31.5V62.1537H52.5V81.5383ZM52.5 55.6922H31.5V36.3076H52.5V55.6922ZM59.5 81.5383V62.1537H77L77.0035 81.5383H59.5Z'
        fill='white'
      />
      <path
        d='M21 16.923H7V29.8461H77V16.923H63V23.3845H56V16.923H28V23.3845H21V16.923Z'
        fill='#73B3AB'
      />
      <rect
        x='17'
        y='56.7273'
        width='11.0303'
        height='21.2727'
        rx='2'
        fill='#F55266'
      />
      <rect
        x='37.4848'
        y='48.4546'
        width='11.0303'
        height='29.5455'
        rx='2'
        fill='#3885FE'
      />
      <rect
        x='57.9697'
        y='39'
        width='11.0303'
        height='39'
        rx='2'
        fill='#FA704D'
        fillOpacity='0.88'
      />
      <mask
        id='mask0_1822_231'
        maskUnits='userSpaceOnUse'
        x='61'
        y='0'
        width='40'
        height='40'>
        <path
          d='M95.1425 5.8585C102.952 13.6682 102.952 26.3318 95.1425 34.1415C87.3325 41.9528 74.6686 41.9528 66.8587 34.1415C59.0471 26.3318 59.0471 13.6682 66.8587 5.8585C74.6686 -1.95283 87.3325 -1.95283 95.1425 5.8585Z'
          fill='#FFB800'
        />
      </mask>
      <g mask='url(#mask0_1822_231)'>
        <path
          d='M95.1425 5.8585C102.952 13.6682 102.952 26.3318 95.1425 34.1415C87.3325 41.9528 74.6686 41.9528 66.8587 34.1415C59.0471 26.3318 59.0471 13.6682 66.8587 5.8585C74.6686 -1.95283 87.3325 -1.95283 95.1425 5.8585Z'
          fill='#FFB800'
        />
        <path
          d='M86.2422 21.1808H79.8468V18.9757H86.2422V21.1808Z'
          fill='#F2F2F5'
        />
        <path
          d='M82.052 21.1808H79.8484V10.2582H82.052V21.1808Z'
          fill='#F2F2F5'
        />
        <path
          d='M82.3863 5.66748H79.9086V-1.79055H82.3863V5.66748Z'
          fill='#F2F2F5'
        />
        <path
          d='M82.3863 42.2133H79.9086V34.3259H82.3863V42.2133Z'
          fill='#F2F2F5'
        />
        <path
          d='M56.3725 18.7556H66.817V21.2332H56.3725V18.7556Z'
          fill='#F2F2F5'
        />
        <path
          d='M95.4763 18.7556H102.073V21.2332H95.4763V18.7556Z'
          fill='#F2F2F5'
        />
      </g>
    </>
  ),
});
